import * as React from 'react';
import {Layout} from "../common/Layout";
import {SEO} from "../components/seo";
import {Grid, ImageList, ImageListItem, ImageListItemBar, Paper} from "@material-ui/core";
import {graphql, Link} from "gatsby";
import {Heading} from "../components/heading";

// Query to be run on build, passes resulting JSON as 'data' prop
export const query = graphql`
  {
    allDirectusGame(
        sort: {
            fields: [name]
            order: ASC
        })  {
        nodes {
          publisher
          name
          directusId
          url_title
          fields {
            logoUrl
          }
          featured
          series {
            sets {
              url_title
            }
          }
        }
    }
  }
`;

// Basic games list component
const GamesPage = ({ data }) => {
    return (
        <Layout>
            <SEO keywords={[`ccg`, `collectible`, `card`, `game`, `trade`]} title="Collectible Card Games Database" />
            <section className="">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Heading text="CCG Database" />
                    </Grid>
                    <Grid item xs={12}>
                        <h2 className={`text-xl uppercase`}>Hot Games</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={`sm:p-4`}>
                            <ImageList className={`justify-center lg:justify-start`} cols={0} gap={16} rowHeight={224}>
                                {data.allDirectusGame.nodes.map(game => {
                                    return game.featured ? (
                                        <ImageListItem className="hover:opacity-75 w-40" cols={1} key={game.directusId}>
                                            <Link className="focus:font-bold" to={`/games/${game.url_title}`}>
                                                <img alt={game.name} height="224" src={game.fields && game.fields.logoUrl} width="144" />
                                                <ImageListItemBar
                                                    title={game.name}
                                                />
                                            </Link>
                                        </ImageListItem>
                                    ) : null
                                })}
                            </ImageList>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <h2 className={`text-xl uppercase`}>All Games</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={`sm:p-4`}>
                            <ImageList className={`justify-center lg:justify-start`} cols={0} gap={16} rowHeight={224}>
                                {data.allDirectusGame.nodes.map(game => (
                                    <ImageListItem className="w-40 hover:opacity-75" cols={1} key={game.directusId}>
                                        <Link className="focus:font-bold" to={`/games/${game.url_title}`}>
                                            <img alt={game.name} height="224" loading="lazy" src={game.fields && game.fields.logoUrl} width="144" />
                                            <ImageListItemBar title={game.name} />
                                        </Link>
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Paper>
                    </Grid>
                </Grid>
            </section>
        </Layout>
    );
};

export default GamesPage;
